<template>
    <li :class="{'is-active':checkActive, 'is-open':open, 'is-hovered':deskHover === index}" @mouseenter="deskHover = index" @mouseleave="mouseLeave">
        <!--check for 2nd level-->
        <template v-if="subnav && subnav.length">

            <template v-if="isClickable">
                <router-link :to="subnav[0].alias || subnav[0].uri" class="link" @click.native="showHover" v-if="!subnav[0].is_external"><icon :name="item.icon_id"/><span>{{ item.title }}</span><svg class="icon arrow-right"><use xlink:href="#angle-right"></use></svg></router-link>
                <a :href="subnav[0].uri" class="link" @click.native="showHover" v-else><icon :name="item.icon_id"/><span>{{ item.title }}</span><svg class="icon arrow-right"><use xlink:href="#angle-right"></use></svg></a>
            </template>
            <span class="link" @click="openLevel2" v-else><icon :name="item.icon_id"/><span>{{ item.title }}</span><svg class="icon arrow-right"><use xlink:href="#angle-right"></use></svg></span>


            <div class="level-2" v-show="hover" @mouseenter="openLevel2" @mouseleave="open = false">
                <ul>
                    <li class="nav-back" @click="openLevel1"><svg class="icon"><use xlink:href="#angle-left"></use></svg> Zurück</li>
                    <li class="title"><span>{{ item.title }}</span></li>
                    <sub-nav-item
                        v-for="(item, index) in subnav"
                        :key="'subnav'+index"
                        :item="item"
                        :subnav="item.children"
                        @reset-nav="mouseLeave"
                    />
                </ul>
            </div>
        </template>
        <router-link :to="getLink(item)" v-else><icon :name="item.icon_id"/><span>{{ item.title }}</span></router-link>
    </li>
</template>

<script>
    import SubNavItem from './SubNavItem'
    import Icon from '../_partials/Icon'
    import { mapGetters } from "vuex";

    export default {
        props:['item', 'name', 'subnav', 'icon', 'index'],
        components: {
            Icon,
            SubNavItem
        },
        computed:{
            ...mapGetters({
                navLevel: "config/navLevel"
            }),
            checkActive(){
                console.log('---');
                console.log(this.item);
                let currentItem = this.findActiveItem([this.item], this.$route.path);

                if (currentItem) {
                    this.$store.commit('content/SET_NAVITEMONE', currentItem);
                    return true;
                }
                return false;
            },
            isClickable(){
                return window.innerWidth > 1139
            }
        },
        data() {
            return {
                open:false,
                hover: true,
                deskHover: null
            }
        },
        watch: {
            'navLevel'(val){
                let that = this;

                if(val === 1) {
                    that.open = false;
                    that.hover = false;

                    setTimeout(function(){
                        that.hover = true;
                    }, 500);
                }
            }
        },
        methods: {
            findActiveItem(items, path) {
                for (let item of items) {
                    if (item.alias === path) {
                        return item;
                    }
                    if (item.children) {
                        let found = this.findActiveItem(item.children, path);
                        if (found) return found;
                    }
                }
                return null;
            },
            getLink(item){
                if(item.is_external)
                    return item.uri;

                if(item.alias && item.alias !== "/")
                    return item.alias;

                return item.internal_path;
            },
            openLevel1(){
                if(window.innerWidth < 1140)
                    this.$store.commit('config/CHANGE_NAV_LEVEL', 1);
            },
            openLevel2(){
                this.$store.commit('config/CHANGE_NAV_LEVEL', 2);
                this.open = true;
            },
            showHover(e){
                if(window.innerWidth < 1140) {
                    e.preventDefault();
                    console.log('prevent');

                    this.openLevel2();
                }else{
                    this.mouseLeave();
                }
            },
            mouseLeave(){
                console.log('mouse leave');
                this.deskHover = null
            }
        }
    };
</script>
